import $ from 'jquery'

class SimpleroMobileHeaderManager {
  // Probably merge this and HeaderDropdownPositioner soon
  constructor(elm) {
    this.onMobileMenuToggleClick = this.onMobileMenuToggleClick.bind(this)
    this.updateShadow = this.updateShadow.bind(this)
    this.elm = $(elm)

    this.mobileMenus = this.elm.find(
      '.simplero-new-header__mobile-menu, .simplero-new-header__user__dropdown'
    )

    this.openMobileMenuToggles = this.elm.find('a[data-opens-menu]')
    this.closeActiveMobileMenuToggle = this.elm.find(
      'a[data-closes-active-menu]'
    )

    this.openMobileMenuToggles.click(this.onMobileMenuToggleClick)
    this.closeActiveMobileMenuToggle.click(this.onMobileMenuToggleClick)

    this.updateShadow()
    $(window).on('scroll', this.updateShadow)
  }

  onMobileMenuToggleClick(e) {
    const $target = $(e.currentTarget)

    let $menu = this.elm.find(this.elm.find($target.data('opens-menu')))
    if ($menu.length === 0) {
      $menu = this.mobileMenus.filter('.open')
    }

    $menu.toggleClass('open')
    $menu.closest('header').toggleClass('menu-open')
    this.openMobileMenuToggles.toggleClass('hidden-below-tablet-lg')
    return this.closeActiveMobileMenuToggle.toggleClass(
      'hidden-below-tablet-lg'
    )
  }

  updateShadow(e) {
    return this.elm.toggleClass('shadow', $(window).scrollTop() > 0)
  }
}

class HeaderDropdownPositioner {
  constructor(elm) {
    this.calculatePosition = this.calculatePosition.bind(this)
    this.getMid = this.getMid.bind(this)
    this.elm = $(elm)
    this.toggle = this.elm.find('.simplero-new-header__dropdown__toggle')
    this.dropdown = this.elm.find('.simplero-new-header__dropdown')
    this.caret = this.elm.find('.simplero-new-header__dropdown__caret')

    $(window).on('resize', this.calculatePosition)
    this.calculatePosition()
  }

  calculatePosition() {
    const toggleMid = this.getMid(this.toggle)

    const dropdownMid = this.getMid(this.dropdown)
    const dropdownCssLeft = parseFloat(this.dropdown.css('left')) || 0
    let offsetDropdownBy = dropdownMid - toggleMid

    const dropdownOffsetLeft = this.dropdown.offset().left
    if (dropdownOffsetLeft - offsetDropdownBy < 16) {
      offsetDropdownBy = dropdownOffsetLeft - 16
    }

    const caretMid = this.getMid(this.caret)
    const caretCssLeft = parseFloat(this.caret.css('left')) || 0
    const offsetCaretBy = caretMid - toggleMid - offsetDropdownBy

    this.dropdown.css('left', dropdownCssLeft - offsetDropdownBy)
    return this.caret.css('left', caretCssLeft - offsetCaretBy)
  }

  getMid(elm) {
    return elm.offset().left + elm.outerWidth() / 2
  }
}

$(function () {
  $('.simplero-new-header__container').each(function () {
    return new SimpleroMobileHeaderManager(this)
  })

  return $('.simplero-new-header__dropdown__container').each(function () {
    return new HeaderDropdownPositioner(this)
  })
})
